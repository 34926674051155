
export class Auth {
  isRefreshingToken = false
  isLogged () {
    return (this.store) ? this.store.getters['auth/loggedIn'] : false
  }
}



const instance = new Auth()

function authHelper (Vue, { router, store }) {
  if (!store || !router) throw new Error('mission required params')
  instance.store = store
  instance.router = router

  router.beforeEach(async (to, from, next) => {
    if (to.matched.length > 1) {
      const authMiddlewareFound = to.matched.find(route => route.meta.middleware && route.meta.middleware.includes('auth'))
      if (!authMiddlewareFound) return next()
    } else if (!to.meta.middleware || !to.meta.middleware.includes('auth')) {
      return next()
    }

    if (store.getters['auth/loggedIn']) {
      return next()
    }

    if (await store.dispatch('auth/trySilentLogin')) {
      return next()
    } else {
      router.push('/')
    }
  })

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get () {
        return instance
      }
    }
  })
}

export default authHelper

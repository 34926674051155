
import _ from 'lodash'

/**
 * @typedef {Object} Dataset Object of stats arrays
 * @property {array} StepCount
 * @property {array} StandCount
 * @property {array} StandTime
 * @property {array} WalkTime
 * @property {array} ActiveTime
 * @property {array} MaxPerimeter
 * @property {array} StepCountObjective
 * @property {array} StandCountObjective
 * @property {array} StandTimeObjective
 * @property {array} WalkTimeObjective
 * @property {array} ActiveTimeObjective
 * @property {array} MaxPerimeterObjective
 * @property {array} WorkoutStars
 * @property {array} ActivityStars
 */

/**
 * Returns computed stats based on raw stats
 * @param {Object} stats Datasets list
 * @return {Object} Computed stats
 */
export function patientStats (stats) {
  const statsDays7 = stats.days7 || null
  const statsDays90 = stats.days91 || null
  
  return {
    usageDaysLast7Days: statsDays7 ? totalUsageDays(statsDays7) : null,
    usageLast7Days: statsDays7 ? totalUsage(statsDays7) : null,
    performancePercentLast7Days: statsDays7 ? totalPerformancePercent(statsDays7) : null,
    selfWorkoutPercentLast7Days: statsDays7 ? totalSelfWorkoutPercent(statsDays7) : null,
    usageDaysLast90days: statsDays90 ? usageDaysLast90days(statsDays90) : null,
    usageLast90days: statsDays90 ? usageLast90days(statsDays90) : null,
    performancePercentLast90Days: statsDays90 ? performancePercentLast90Days(statsDays90) : null,
    selfWorkoutPercentLast90Days: statsDays90 ? selfWorkoutPercentLast90Days(statsDays90) : null,
  }
}

/**
 * Returns an array of stats in a dataset
 * @param {string} dataName Stat name
 * @param {Dataset} dataset
 * @return {array} Array of stats
 */
export function getData (dataName, dataset) {
  if (!dataset[dataName]) return null
  return dataset[dataName]
}

/**
 * Returns the percent of a stat based on its objective value
 * @param {string|array} dataName Stat name or array of stat names
 * @param {Dataset} dataset
 * @return {number} Percent value
 */
export function dataPercentage (dataName, dataset) {
  if (dataName instanceof Array) {
    return Math.round(dataName.reduce((acc, value) => acc + dataPercentage(value, dataset), 0) / dataName.length)
  }

  const usageDays = getData('ActiveTime', dataset).map((data) => data > 7200)
  const usageDaysCount = totalUsageDays(dataset)
  const data = getData(dataName, dataset) || 0
  const objective = getData(`${dataName}Objective`, dataset) || 0

  const dataPercent = data.reduce((acc, value, i) => {
    return usageDays[i] ? acc + Math.min(value * 100 / objective[i], 100) : acc
  }, 0) / usageDaysCount

  return dataPercent | 0
}

/**
 * Returns the total usage days in a dataset
 * @param {Dataset} stats
 * @return {number} Total usage days
 */
export function totalUsageDays (stats) {
  return getData('ActiveTime', stats).reduce((sum, data) => {
    return data > 7200 ? sum + 1 : sum
  }, 0)
}

/**
 * Returns the total usage time in a dataset
 * @param {Dataset} stats
 * @return {number} Total usage time (hours)
 */
export function totalUsage (stats) {
  const usageDays = getData('ActiveTime', stats).map((data) => data > 7200)
  const usageDaysCount = totalUsageDays(stats)

  const activeTime = getData('ActiveTime', stats).reduce((sum, activeTime, i) => {
    return usageDays[i] ? sum + activeTime : sum
  }, 0) / usageDaysCount | 0

  const walkTime = getData('WalkTime', stats).reduce((sum, walkTime, i) => {
    return usageDays[i] ? sum + walkTime : sum
  }, 0) / usageDaysCount | 0

  const standTime = getData('StandTime', stats).reduce((sum, standTime, i) => {
    return usageDays[i] ? sum + standTime : sum
  }, 0) / usageDaysCount | 0

  return ((activeTime + walkTime + standTime) / 3600).toFixed(0)
}

/**
 * Returns the total activity percent in a dataset
 * @param {Dataset} stats
 * @return {number} Activity percent
 */
export function totalActivityPercent (stats) {
  return dataPercentage(['StandTime', 'StandCount', 'ActiveTime'], stats)
}

/**
 * Returns the total walk percent in a dataset
 * @param {Dataset} stats
 * @return {number} Walk percent
 */
export function totalWalkPercent (stats) {
  return dataPercentage(['StepCount', 'WalkTime', 'MaxPerimeter'], stats)
}

/**
 * Returns the count of self workouts in a dataset
 * @param {Dataset} stats
 * @return {number} Self workouts count
 */
export function totalSelfWorkout (stats) {
  return stats.WorkoutStars.reduce((acc, workout) => acc + workout)
}

/**
 * Returns the percent of self workouts in a dataset
 * @param {Dataset} stats
 * @return {number} Self workouts percent
 */
export function totalSelfWorkoutPercent (stats) {
  return stats.WorkoutStars.reduce((acc, workout) => acc + workout) * 100 / stats.WorkoutStars.length | 0
}

/**
 * Returns the percent of self workouts in a dataset
 * @param {Dataset} stats
 * @return {number} Self workouts percent
 */
export function totalPerformancePercent (stats) {
  const selfWorkout = totalSelfWorkout(stats)
  const activityPercent = totalActivityPercent(stats)
  const walkPercent = totalWalkPercent(stats)

  return ((selfWorkout + Math.min(100, activityPercent) + Math.min(100, walkPercent)) / 3).toFixed(0)
}

/**
 * Split a dataset into datasets of n datas
 * @param {Dataset} stats
 * @param {number} size Size of splitted datasets
 * @return {array} Array of datasets
 */
export function splitDataset (stats, size) {
  const chunkedStats = {}
  let chunkCount = 0
  Object.keys(stats).map(key => {
    chunkCount = _.chunk(stats[key], size).length
    return chunkedStats[key] = _.chunk(stats[key], size)
  })

  const formattedStats = []
  for (let i = 0; i < chunkCount; i++) {
    const formattedStat = {}
    Object.keys(chunkedStats).map(key => {
      return formattedStat[key] = chunkedStats[key][i]
    })

    formattedStats.push(formattedStat)
  }

  return formattedStats
}

/**
 * Returns an array of usage days by week for the last 3 months
 * @param {Dataset} stats
 * @return {array} Array of usage days by week
 */
export function usageDaysLast90days (stats) {
  return splitDataset(stats, 7).map(stats => totalUsageDays(stats))
}

/**
 * Returns an array of usage time by week for the last 3 months
 * @param {Dataset} stats
 * @return {array} Array of usage time by week (hours)
 */
export function usageLast90days (stats) {
  return splitDataset(stats, 7).map(stats => totalUsage(stats))
}

/**
 * Returns an array of self workouts by week for the last 3 months
 * @param {Dataset} stats
 * @return {array} Array of self workouts by week
 */
export function selfWorkoutPercentLast90Days (stats) {
  return splitDataset(stats, 7).map(stats => totalSelfWorkoutPercent(stats))
}

/**
 * Returns an array of performance percent by week for the last 3 months
 * @param {Dataset} stats
 * @return {array} Array of performance percent by week
 */
export function performancePercentLast90Days (stats) {
  return splitDataset(stats, 7).map(stats => totalPerformancePercent(stats))
}

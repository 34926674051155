
import { totalUsageDays, totalUsage, totalPerformancePercent, usageDaysLast90days, usageLast90days, performancePercentLast90Days } from './patientStats'

export function establishmentStats (stats) {
  const statsDays7 = stats.days7 || null
  const statsDays90 = stats.days91 || null
  
  return {
    usageDaysPercentLast7Days: statsDays7 ? establishmentUsageDaysPercent(statsDays7) : null,
    usageLast7Days: statsDays7 ? establishmentTotalUsageDays(statsDays7) : null,
    performancePercentLast7Days: statsDays7 ? establishmentPerformancePercent(statsDays7) : null,
    usageDaysLast90days: statsDays90 ? establishmentUsageDaysLast90days(statsDays90) : null,
    usageLast90days: statsDays90 ? establishmentUsageLast90days(statsDays90) : null,
    performancePercentLast90Days: statsDays90 ? establishmentPerformancePercentLast90days(statsDays90) : null,
  }
}

export function establishmentUsageDaysPercent (stats) {
  const patientsUsageDaysPercent = stats.map(patient => {
    return totalUsageDays(patient.stats) * 100 / patient.stats.ActiveTime.length
  })

  return Math.round(patientsUsageDaysPercent.reduce((acc, usageDaysPercent) => acc + usageDaysPercent, 0) / patientsUsageDaysPercent.length)
}

export function establishmentTotalUsageDays (stats) {
  const patientsTotalUsageDays = stats.map(patient => {
    return Number(totalUsage(patient.stats))
  })

  return patientsTotalUsageDays.reduce((acc, usageDays) => acc + usageDays, 0) / patientsTotalUsageDays.length
}

export function establishmentPerformancePercent (stats) {
  const patientsPerformancePercent = stats.map(patient => {
    return Number(totalPerformancePercent(patient.stats))
  })

  return Math.round(patientsPerformancePercent.reduce((acc, performancePercent) => acc + performancePercent, 0) / patientsPerformancePercent.length)
}

export function establishmentUsageDaysLast90days (stats) {
  const patientsUsageDaysLast90Days = stats.map(patient => {
    return usageDaysLast90days(patient.stats)
  })

  return transposeArray(patientsUsageDaysLast90Days).map(usages => {
    return usages.reduce((acc, usage) => acc + Number(usage), 0) / usages.length
  })
}

export function establishmentUsageLast90days (stats) {
  const patientsUsageLast90Days = stats.map(patient => {
    return usageLast90days(patient.stats)
  })

  return transposeArray(patientsUsageLast90Days).map(usages => {
    return usages.reduce((acc, usage) => acc + Number(usage), 0) / usages.length
  })
}

export function establishmentPerformancePercentLast90days (stats) {
  const patientsPerformancePercentLast90Days = stats.map(patient => {
    return performancePercentLast90Days(patient.stats)
  })

  return transposeArray(patientsPerformancePercentLast90Days).map(performancePercents => {
    return performancePercents.reduce((acc, performancePercent) => acc + Number(performancePercent), 0) / performancePercents.length
  })
}

const transposeArray = arr => arr.reduce((m, r) => (r.forEach((v, i) => (m[i] ??= [], m[i].push(v))), m), [])

import axios from 'axios'
import router from '../router'
import store from '../store'
const request =
  axios.create({
    baseURL: ''
  })
request.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem('accessToken')
    config.headers = {
      Authorization: `Bearer ${accessToken}`
    }
    config.params = { ...config.params, timestamp: Date.now() };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
setHeader('Cache-Control', 'no-cache')
setHeader('Pragma', 'no-cache')
setHeader('Expires', '0')
for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch', 'postResponse', 'getResponse', 'deleteResponse', 'patchResponse', 'putResponseS']) {
  if (method.includes("Response")) {
    const x = method.replace('Response', "")
    request['$' + method] = function () {
      return this[x].apply(request, arguments).then(async (res) => {

        return res
      })
    }
  } else {
    request['$' + method] = function () {
      return request[method].apply(request, arguments).then(async (res) => {

        return res.data
      })
    }
  }
}
request.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const refreshResponse = await store.dispatch('auth/refreshToken')
          if (refreshResponse) {
            originalConfig.headers = {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
            return axios(originalConfig);
          }
          store.dispatch('logout')
          router.push({ name: 'Login' })
        } catch (_error) {
          store.dispatch('logout')
          router.push({ name: 'Login' })
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);


export function setHeader(key, value) {
  request.defaults.headers.common[key] = value
}
function axiosHelper(Vue, options) {
  request.defaults.baseURL = options.apiUrl
  if (options.store) { options.store.$axios = request }
  Object.defineProperties(Vue.prototype, {
    $axios: {
      get() {
        return request
      }
    }
  })
}

export { axiosHelper, request } 

import Vue from 'vue'
import Vuex from 'vuex'

import admin from './admin'
import guides from './guides'
import applications from './applications'
import auth from './auth'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navigations: {
      main: [
        { name: `Base de données`, viewName: 'DataBaseHome' },
        { name: `Suivi`, viewName: 'MonitoringHome' },
        { name: `Etat flotte`, viewName: 'FlotteOxilio' },
        { name: `Lancement`, viewName: 'LancementHome' }
      ],
      admin: [
        { name: `Exercices`, viewName: 'ExercisesList' },
        { name: `Familles d'exercices`, viewName: 'ExercisesFamilies' },
        { name: `Programmes`, viewName: 'ProgramsList' },
        { name: `Programmes recommandés`, viewName: 'RecommandedProgramsList' },
        { name: `Activités Physiques Adaptées`, viewName: 'ActivitiesList' },
        { name: `Coachings`, viewName: 'CoachingList' },
        { name: `Pathologies & symptômes`, viewName: 'PathologiesList' },
        { name: `Matériel`, viewName: 'EquipmentsList' },
        { name: `Étapes de réglage`, viewName: 'SettingsSteps' },
        { name: `Rangs`, viewName: 'Ranks' },
        { name: `Succès`, viewName: 'Achievements' },
        { name: `Guides`, viewName: 'GuidesList' },
        { name: `Prescripteurs`, viewName: 'PrescribersList' },
        { name: `Proches Familiaux`, viewName: 'FamilyMembersList' },
        { name: `Établissements`, viewName: 'EstablishmentsList' },
        { name: `Employés`, viewName: 'EmployeesList' }
      ]
    }
  },
  getters: {
    navMain: (state) => {
      return state.navigations.main
    },
    navDatabase: (state) => {
      return state.navigations.admin
    }
  },
  modules: {
    auth,
    admin,
    guides,
    applications
  },
  strict: debug
})

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const UPDATE_USER = 'UPDATE_USER'

export const mutations = {

  [LOGIN_SUCCESS] (state, { accessToken, refreshToken }) {
    state.loggedIn = true
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
  },

  [LOGOUT] (state) {
    localStorage.removeItem('userInfo')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    state.loggedIn = false
    state.user = null
  },

  [UPDATE_USER] (state, user) {
    state.user = user
    localStorage.setItem('userInfo', JSON.stringify(user))
  }
}

import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import i18n from './utils/i18n'
import VueMoment from "vue-moment";
import { axiosHelper, instance } from './helpers/axios'
import authHelper from './helpers/auth'

import clickout from './directives/clickoutside'

Vue.config.productionTip = false

Vue.use(axiosHelper, { apiUrl: process.env.VUE_APP_API_URL, store })
Vue.use(authHelper, { store, router, axios: instance })

Vue.directive('clickout', clickout)
const moment = require("moment");
Vue.use(VueMoment, {
  moment
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

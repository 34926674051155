<template>
  <transition name="slide">
    <div class="toast" v-if="currentMessage" :key="currentMessage.id" :class="currentMessage.type">
      <div class="timer" ref="timer"></div>
      <div class="text">{{ currentMessage.message }}</div>
    </div>
  </transition>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "Toast",
  components: {
  },
  data() {
    return {
      currentMessage: undefined,
      messages: [],
    };
  },
  mounted() {
    this.$root.$on("sendToast", (message) => {
      this.messages.push({...message, id: Math.random()});
      if (!this.currentMessage) {
        this.showNextMessage();
      }
    });
  },
  methods: {
    showNextMessage() {
      this.currentMessage = this.messages.shift();
      if (this.currentMessage) {
        const time = this.currentMessage.time || 3000;
        this.$nextTick(() => {
          this.animateTimer(time);
        });
        setTimeout(this.showNextMessage, time);
      }
    },
    animateTimer(time) {
      gsap.set(this.$refs.timer,{
        width: '90%'
      })
      gsap.to(this.$refs.timer, {
        width: "0",
        duration: time / 1000,
        ease: "linear",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  bottom: 64px;
  left: calc(50% - 150px);
  min-height: 64px;
  width: 300px;
  margin: 0 auto;
  background-color: white;
  border-radius: 16px;
  box-shadow: 1px 2px 7px 0 rgba(35, 29, 29, 0.24);
  display: flex;
  align-items: center;
  padding: 16px;
  color: var(--darkest-color);
  z-index: 101;
  font-size: 0.9em;
  &.success {
    .timer {
      background-color: $secondary-color;
    }
  }
  &.warning {
    .timer {
      background-color: $orange-color;
    }
  }
  .timer {
    position: absolute;
    height: 8px;
    top: -4px;
    left: 5%;
    width: 90%;
    border-radius: 4px;
    background-color: $red-color;
  }
  .icon {
    height: 32px;
    margin-right: 8px;
  }
  .text {
    white-space: pre-wrap;
    font-family: "Mulish";
    font-weight: bold;
  }
}

.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-enter,
.slide-leave-to {
  transform: translateY(128px);
}
</style>

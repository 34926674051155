<template>
  <div id="app">
    <Toast />
    <router-view/>
  </div>
</template>

<script>
import Toast from '@/components/Toast.vue'
export default {
  components: {
    Toast
  },
  data () {
    return {
      loading: true
    }
  },
  async created () {
    try {
      if (this.$store.getters['auth/user']) {
        await this.$store.dispatch('auth/trySilentLogin')
      }
    } catch (e) { console.error(e) }
    this.loading = false
  }
}
</script>

<style lang="scss">
html, body {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;
  font-family: $serif-font;
  color: $text-color;

  background: $light-background-color;
}

*{
  font-family: $serif-font;
}
</style>

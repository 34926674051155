import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state = {
  loggedIn: false,
  accessToken: undefined,
  refreshToken: undefined,
  user: null
}
const userInfo = localStorage.getItem('userInfo')
const accessToken = localStorage.getItem('accessToken')
const refreshToken = localStorage.getItem('refreshToken')

if (userInfo && accessToken) {
  try {
    if (JSON.parse(userInfo)) {
      state.user = JSON.parse(userInfo)
      state.accessToken = accessToken
      state.refreshToken = refreshToken
    }
  } catch (e) {
    console.log(e)
  }
}
const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}

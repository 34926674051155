export const actions = {

  async getAll() {
    const { chapters } = await this.$axios.$get(`/admin/GuideLibrary/chapters`)
    return chapters
  },
  async createChapter() {
    const { chapter } = await this.$axios.$post(`/admin/GuideLibrary/chapters`)
    return chapter
  },
  async editChapter(_, { chapterId, title }) {
    await this.$axios.$patch(`/admin/GuideLibrary/chapters/${chapterId}`, { title })
  },
  async deleteChapter(_, { chapterId }) {
    await this.$axios.$delete(`/admin/GuideLibrary/chapters/${chapterId}`)
  },
  async orderChapters(_, { chaptersId }) {
    await this.$axios.$patch(`/admin/GuideLibrary/chapters/order`, { orderedChaptersIds: chaptersId })
  },
  async createGuide(_, { chapterId }) {
    const { guide } = await this.$axios.$post(`/admin/GuideLibrary/chapters/${chapterId}`)
    return guide
  },
  async editGuide(_, { chapterId, guideId, fields }) {
    await this.$axios.$patch(`/admin/GuideLibrary/chapters/${chapterId}/guides/${guideId}`, fields)
  },
  async deleteGuide(_, { chapterId, guideId }) {
    await this.$axios.$delete(`/admin/GuideLibrary/chapters/${chapterId}/guides/${guideId}`)
  },
  async orderGuides(_, { chapterId, guidesId }) {
    await this.$axios.$patch(`/admin/GuideLibrary/chapters/${chapterId}/guides/order`, { orderedGuidesIds: guidesId })
  },

}

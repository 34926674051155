import { patientStats } from '@/utils/patientStats'
import { establishmentStats } from '@/utils/establishmentStats'
import { rehabilitatorStats } from '@/utils/rehabilitatorStats'
import { randomColorHex } from '@/utils/colors'
import Vue from 'vue'
import _ from 'lodash'

export const SET_PATIENTS = 'SET_PATIENTS'
export const SET_PATIENTS_APPLICATIONS = 'SET_PATIENTS_APPLICATIONS'
export const SET_PATIENT_STATS = 'SET_PATIENT_STATS'
export const SELECT_PATIENT = 'SELECT_PATIENT'
export const UNSELECT_PATIENT = 'UNSELECT_PATIENT'
export const SET_ESTABLISHMENTS = 'SET_ESTABLISHMENTS'
export const SET_ESTABLISHMENTS_APPLICATIONS = 'SET_ESTABLISHMENTS_APPLICATIONS'
export const SET_REEDUCATORS_APPLICATIONS = 'SET_REEDUCATORS_APPLICATIONS'
export const SET_ESTABLISHMENT_STATS = 'SET_ESTABLISHMENT_STATS'
export const SELECT_ESTABLISHMENT = 'SELECT_ESTABLISHMENT'
export const UNSELECT_ESTABLISHMENT = 'UNSELECT_ESTABLISHMENT'
export const SET_REHABILITATORS = 'SET_REHABILITATORS'
export const SET_REHABILITATOR_PATIENTS = 'SET_REHABILITATOR_PATIENTS'
export const SET_REHABILITATOR_STATS = 'SET_REHABILITATOR_STATS'
export const SELECT_REHABILITATOR = 'SELECT_REHABILITATOR'
export const UNSELECT_REHABILITATOR = 'UNSELECT_REHABILITATOR'
export const SET_EXERCISES = 'SET_EXERCISES'
export const SET_PROGRAMS = 'SET_PROGRAMS'
export const SET_PATHOLOGIES = 'SET_PATHOLOGIES'
export const SET_EQUIPMENTS = 'SET_EQUIPMENTS'
export const SET_RANKS = 'SET_RANKS'
export const SET_ACTIVITIES = 'SET_ACTIVITIES'
export const SET_DEVICES = 'SET_DEVICES'

export const mutations = {
  [SET_PATIENTS](state, patients) {
    state.patients = patients.map(patient => {
      let firstName = patient.firstName.toLowerCase()
      let lastName = patient.lastName.toLowerCase()
      firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)

      return {
        ...patient,
        firstName,
        lastName,
        stats: {},
        computedStats: {},
        color: randomColorHex()
      }
    })
  },
  [SET_PATIENTS_APPLICATIONS](state, applications) {
    state.patientApplications = applications.map(application => {
      let firstName = application.firstName.toLowerCase()
      let lastName = application.lastName.toLowerCase()
      firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)

      return {
        ...application,
        firstName,
        lastName,
        computedStats: {},
        color: randomColorHex()
      }
    })
  },
  [SET_PATIENT_STATS]({ patients }, { patientId, stats, days }) {
    const patientIndex = patients.findIndex(patient => patient.patientId === patientId)

    const newStats = Object.assign({}, patients[patientIndex].stats, {
      [`days${days}`]: stats
    })

    Vue.set(patients[patientIndex], 'computedStats', patientStats(newStats))
  },
  [SELECT_PATIENT]({ selectedPatients }, patientId) {
    if (!selectedPatients.includes(patientId)) {
      selectedPatients.push(patientId)
    }
  },
  [UNSELECT_PATIENT]({ selectedPatients }, patientId) {
    if (selectedPatients.includes(patientId)) {
      const index = selectedPatients.indexOf(patientId)
      selectedPatients.splice(index, 1)
    }
  },

  [SET_ESTABLISHMENTS](state, establishments) {
    state.establishments = establishments.map(establishment => {
      return {
        ...establishment,
        stats: {},
        computedStats: {},
        color: randomColorHex()
      }
    })
  },
  [SET_REEDUCATORS_APPLICATIONS](state, reeducators) {
    state.reeducatorsApplications = reeducators.map(reeducator => {
      return {
        ...reeducator,
        stats: {},
        computedStats: {},
        color: randomColorHex()
      }
    })
  },
  [SET_ESTABLISHMENTS_APPLICATIONS](state, establishments) {
    state.establishmentsApplications = establishments.map(establishment => {
      return {
        ...establishment,
        stats: {},
        computedStats: {},
        color: randomColorHex()
      }
    })
  },
  [SET_ESTABLISHMENT_STATS]({ establishments }, { establishmentId, stats, days }) {
    const establishmentIndex = establishments.findIndex(establishment => establishment.establishmentId === establishmentId)

    const newStats = Object.assign({}, establishments[establishmentIndex].stats, {
      [`days${days}`]: stats
    })

    Vue.set(establishments[establishmentIndex], 'computedStats', establishmentStats(newStats))
  },
  [SELECT_ESTABLISHMENT]({ selectedEstablishments }, establishmentId) {
    if (!selectedEstablishments.includes(establishmentId)) {
      selectedEstablishments.push(establishmentId)
    }
  },
  [UNSELECT_ESTABLISHMENT]({ selectedEstablishments }, establishmentId) {
    if (selectedEstablishments.includes(establishmentId)) {
      const index = selectedEstablishments.indexOf(establishmentId)
      selectedEstablishments.splice(index, 1)
    }
  },

  [SET_REHABILITATORS](state, rehabilitators) {
    state.rehabilitators = rehabilitators.map(rehabilitator => {
      return {
        ...rehabilitator,
        patients: [],
        stats: {},
        computedStats: {},
        color: randomColorHex()
      }
    })
  },
  [SET_REHABILITATOR_PATIENTS]({ rehabilitators }, { reeducatorId, patients }) {
    const rehabilitatorIndex = rehabilitators.findIndex(r => r.reeducatorId === reeducatorId)
    Vue.set(rehabilitators[rehabilitatorIndex], 'patients', patients)
    Vue.set(rehabilitators[rehabilitatorIndex], 'computedStats', rehabilitatorStats(patients))
  },
  [SET_REHABILITATOR_STATS]({ rehabilitators }, { reeducatorId, stats, days }) {
    const rehabilitatorIndex = rehabilitators.findIndex(rehabilitator => rehabilitator.reeducatorId === reeducatorId)

    Object.assign({}, rehabilitators[rehabilitatorIndex].stats, {
      [`days${days}`]: stats
    })
  },
  [SELECT_REHABILITATOR]({ selectedRehabilitators }, reeducatorId) {
    if (!selectedRehabilitators.includes(reeducatorId)) {
      selectedRehabilitators.push(reeducatorId)
    }
  },
  [UNSELECT_REHABILITATOR]({ selectedRehabilitators }, reeducatorId) {
    if (selectedRehabilitators.includes(reeducatorId)) {
      const index = selectedRehabilitators.indexOf(reeducatorId)
      selectedRehabilitators.splice(index, 1)
    }
  },

  [SET_EXERCISES](state, exercises) {
    state.exercises = _.sortBy(exercises, ['name'])
  },
  [SET_PROGRAMS](state, programs) {
    state.programs = programs
  },
  [SET_PATHOLOGIES](state, pathologies) {
    state.pathologies = pathologies
  },
  [SET_EQUIPMENTS](state, equipments) {
    state.equipments = equipments
  },
  [SET_RANKS](state, ranks) {
    state.ranks = ranks
  },
  [SET_ACTIVITIES](state, activities) {
    state.activities = activities
  },
  [SET_DEVICES](state, devices) {
    state.devices = devices
  }
}

export const getters = {
  patients: ({ patients }) => patients,
  patientApplications: ({ patientApplications }) => patientApplications,
  selectedPatients: ({ selectedPatients }) => selectedPatients,
  establishments: ({ establishments }) => establishments,
  selectedEstablishments: ({ selectedEstablishments }) => selectedEstablishments,
  rehabilitators: ({ rehabilitators }) => rehabilitators,
  selectedRehabilitators: ({ selectedRehabilitators }) => selectedRehabilitators,
  exercises: ({ exercises }) => exercises,
  getExercise: ({ exercises }) => (exerciseId) => {
    return exercises.find(exercise => exercise.id === exerciseId) ?? null
  },
  programs: ({ programs }) => programs,
  getProgram: ({ programs }) => (programId) => {
    return programs.find(program => program.id === programId) ?? null
  },
  pathologies: ({ pathologies }) => pathologies,
  equipments: ({ equipments }) => equipments,
  ranks: ({ ranks }) => ranks,
  activities: ({ activities }) => activities,
  getActivity: ({ activities }) => (acitivityId) => {
    return activities.find(activity => activity.id === acitivityId) ?? null
  },
  devices: ({ devices }) => (devices),
  establishmentsApplications: ({ establishmentsApplications }) => (establishmentsApplications),
  reeducatorsApplications: ({ reeducatorsApplications }) => (reeducatorsApplications)
}

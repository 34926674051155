import { setHeader } from '../../helpers/axios'
import * as types from './mutations'

function parseJwt (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export const actions = {

  async login ({ commit }, { code }) {
    const response = await this.$axios.$post(`/auth/microsoft?code=${code}`)
    const { accessToken } = response.claim

    setHeader('Authorization', `Bearer ${accessToken}`)
    commit(types.LOGIN_SUCCESS, response.claim)
    commit(types.UPDATE_USER, parseJwt(accessToken))
  },

  async logout ({ commit }) {
    setHeader('Authorization', false)
    commit(types.LOGOUT)
  },

  async refreshToken ({ commit, dispatch }) {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    if (!accessToken || !refreshToken) { throw new Error('Token is missing for refreshToken') }
    const response = await this.$axios.$post('/auth/refreshToken', { accessToken: accessToken, refreshToken: refreshToken })
    setHeader('Authorization', `Bearer ${response.claim.accessToken}`)
		commit(types.LOGIN_SUCCESS, response.claim)
		commit(types.UPDATE_USER, parseJwt(response.claim.accessToken))
		dispatch('updateUserInfos')
    return response.claim
  },

  async trySilentLogin ({ commit, dispatch, state }) {
    if (!state.accessToken) return false
    const jwt = parseJwt(state.accessToken)
    const exp = new Date(jwt.exp * 1000)
    if (exp < new Date()) {
      try {
        const claim = await dispatch('refreshToken')
        setHeader('Authorization', `Bearer ${claim.accessToken}`)
        commit(types.LOGIN_SUCCESS, claim)
        commit(types.UPDATE_USER, parseJwt(claim.accessToken))
        return true
      } catch (e) {
        dispatch('logout')
        return false
      }
    }

    setHeader('Authorization', `Bearer ${state.accessToken}`)
    commit(types.LOGIN_SUCCESS, { accessToken: state.accessToken, refreshToken: state.refreshToken })
    return true
  }
}

export const actions = {

  async getPatientsApplication (_, status) {
    const { applications } = await this.$axios.$get(`admin/applications/patient?${status.map(s=>'statuses='+s).join('&')}`)
    return applications
  },
  async updatePatientApplication (_, {applicationId, fields}) {
    const { applications } = await this.$axios.$patch(`admin/applications/patient/${applicationId}`,fields)
    return applications
  },
  async validatePatientApplication (_, {applicationId}) {
    const { applications } = await this.$axios.$post(`/admin/applications/patient/${applicationId}/signupNotification`)
    return applications
  },

  async getEstablishmentsApplication (_, status) {
    const { applications } = await this.$axios.$get(`admin/applications/Establishment?${status.map(s=>'statuses='+s).join('&')}`)
    return applications
  },
  async updateEstablishmentApplication (_, {applicationId, fields}) {
    const { applications } = await this.$axios.$patch(`admin/applications/Establishment/${applicationId}`,fields)
    return applications
  },
  async inviteEstablishmentApplication (_, {applicationId}) {
    const { applications } = await this.$axios.$post(`/admin/applications/Establishment/${applicationId}/signupNotification`)
    return applications
  },
  async validateEstablishmentApplication (_, {applicationId}) {
    const { applications } = await this.$axios.$post(`/admin/applications/Establishment/${applicationId}/billingInformationValidation`)
    return applications
  },
  

}

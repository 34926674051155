import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const dataBase = {
  path: '/base-de-donnees',
  meta: { middleware: ['auth'] },
  component: { template: '<router-view></router-view>' },
  children: [
    { path: '', name: 'DataBaseHome', component: () => import('../views/admin/DataBaseHome.vue') },

    { path: 'exercices', name: 'ExercisesList', component: () => import('../views/admin/ExercisesList.vue') },
    { path: 'exercices/new', name: 'AddExercise', component: () => import('../views/admin/Exercise.vue'), props: { mode: 'add' } },
    { path: 'exercices/:id', name: 'Exercise', component: () => import('../views/admin/Exercise.vue') },
    { path: 'familles-exercices', name: 'ExercisesFamilies', component: () => import('../views/admin/ExercisesFamilies.vue') },

    { path: 'programmes', name: 'ProgramsList', component: () => import('../views/admin/ProgramsList.vue') },
    { path: 'recommanded-programmes', name: 'RecommandedProgramsList', component: () => import('../views/admin/RecommandedProgramsList.vue') },
    { path: 'programmes/messages-audio', name: 'ProgramAudioFiles', component: () => import('../views/admin/ProgramAudioFiles.vue') },
    { path: 'programmes/new', name: 'AddProgram', component: () => import('../views/admin/Program.vue'), props: { mode: 'add' } },
    { path: 'programmes/:id', name: 'Program', component: () => import('../views/admin/Program.vue') },

    { path: 'activities', name: 'ActivitiesList', component: () => import('../views/admin/ActivitiesList.vue') },
    { path: 'activities/new', name: 'AddActivity', component: () => import('../views/admin/Activity.vue'), props: { mode: 'add' } },
    { path: 'activities/:id', name: 'Activity', component: () => import('../views/admin/Activity.vue') },

    { path: 'coachings/', name: 'CoachingList', component: () => import('../views/admin/CoachingList.vue') },
    { path: 'coachings/new', name: 'AddCoaching', component: () => import('../views/admin/Coaching.vue'), props: { mode: 'add' } },
    { path: 'coachings/:id', name: 'Coaching', component: () => import('../views/admin/Coaching.vue') },

    { path: 'pathologies', name: 'PathologiesList', component: () => import('../views/admin/PathologiesList.vue') },
    { path: 'materiel', name: 'EquipmentsList', component: () => import('../views/admin/EquipmentsList.vue') },
    { path: 'etapes-reglages', name: 'SettingsSteps', component: () => import('../views/admin/SettingsSteps.vue') },
    { path: 'rangs', name: 'Ranks', component: () => import('../views/admin/Ranks.vue') },
    { path: 'succes', name: 'Achievements', component: () => import('../views/admin/Achievements.vue') },
    { path: 'guides', name: 'GuidesList', component: () => import('../views/admin/GuidesList.vue') },
    { path: 'prescripteurs', name: 'PrescribersList', component: () => import('../views/admin/PrescribersList.vue') },
    { path: 'proches-famille', name: 'FamilyMembersList', component: () => import('../views/admin/FamilyMembersList.vue') },
    { path: 'etablissements', name: 'EstablishmentsList', component: () => import('../views/admin/EstablishmentsList.vue') },

    { path: 'employes', name: 'EmployeesList', component: () => import('../views/admin/EmployeesList.vue') },
    { path: 'employes/new', name: 'AddEmployee', component: () => import('../views/admin/Employee.vue'), props: { mode: 'add' } },
    { path: 'employes/:id', name: 'Employee', component: () => import('../views/admin/Employee.vue') }
  ]
}

const monitoring = {
  path: '/suivi',
  name: 'MonitoringHome',
  meta: { middleware: ['auth'] },
  component: () => import('../views/monitoring/MonitoringHome.vue'),
  children: [
    { path: 'patients', name: 'MonitoringPatients', component: () => import('../views/monitoring/MonitoringPatients.vue') },
    { path: 'patients-charts', name: 'MonitoringPatientsCharts', component: () => import('../views/monitoring/MonitoringPatientsCharts.vue') },
    { path: 'etablissements', name: 'MonitoringEstablishments', component: () => import('../views/monitoring/MonitoringEstablishments.vue') },
    { path: 'etablissements-charts', name: 'MonitoringEstablishmentsCharts', component: () => import('../views/monitoring/MonitoringEstablishmentsCharts.vue') },
    { path: 'reeducateurs', name: 'MonitoringRehabilitators', component: () => import('../views/monitoring/MonitoringRehabilitators.vue') },
    { path: 'reeducateurs-charts', name: 'MonitoringRehabilitatorsCharts', component: () => import('../views/monitoring/MonitoringRehabilitatorsCharts.vue') },
    { path: 'patients-waiting', name: 'WaitingPatient', component: () => import('../views/monitoring/MonitoringWaitingPatients.vue') },
    { path: 'establishments-waiting', name: 'WaitingEstablishment', component: () => import('../views/monitoring/MonitoringWaitingEstablishments.vue') },
  ]
}
const flotte = {
  path: '/flotte',
  name: 'FlotteOxilio',
  meta: { middleware: ['auth'] },
  component: () => import('../views/flotte/FlotteOxilio.vue'),
  children: [
    { path: 'FlotteOxilio', name: 'FlotteOxilio', component: () => import('../views/flotte/FlotteOxilio.vue') }
  ]
}
const lancement = {
  path: '/lancement',
  name: 'LancementHome',
  meta: { middleware: ['auth'] },
  component: () => import('../views/lancement/LancementHome.vue'),
  children: [
    { path: 'LancementEtablissement', name: 'LancementEtablissement', component: () => import('../views/lancement/LancementEtablissement.vue') },
    { path: 'LancementReeducator', name: 'LancementReeducator', component: () => import('../views/lancement/LancementReeducator.vue') },
    { path: 'LancementPatient', name: 'LancementPatient', component: () => import('../views/lancement/LancementPatient.vue') }
  ]
}


const routes = [
  { path: '/', name: 'Login', component: () => import('../views/authentification/Login.vue') },
  { path: '/login/microsoft', name: 'AuthConfirm', component: () => import('../views/authentification/AuthConfirm.vue') },
  dataBase,
  monitoring,
  flotte,
  lancement
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import * as types from './mutations'

export const actions = {
  async getExercises({ commit, state }, { forceUpdate = true } = {}) {
    if (state.exercises.length > 0 && !forceUpdate) return
    let data = null
    const { exercises } = await this.$axios.$get('Exercises')
    data = [
      ...exercises.map((exercice) => {
        return {
          ...exercice,
          thumbnailUrl: exercice?.thumbnail?.url ? exercice.thumbnail.url : null,
          introVideoUrl: exercice?.introVideo?.url ? exercice.introVideo.url : null,
          videoUrl: exercice?.video?.url ? exercice.video.url : null
        }
      })
    ]
    commit(types.SET_EXERCISES, data)
    return exercises
  },
  async addExercise({ dispatch }, { exercise }) {
    await this.$axios.$post('Exercises', exercise)
    dispatch('getExercises', { forceUpdate: true })
  },
  async editExercise({ dispatch }, { exercise }) {
    await this.$axios.$patch(`Exercises/${exercise.id}`, { exercise: exercise })
    dispatch('getExercises', { forceUpdate: true })
  },

  async getPrograms({ commit }) {
    const { programs } = await this.$axios.$get('Programs')
    commit(types.SET_PROGRAMS, programs)
    return programs
  },
  async addProgram({ dispatch }, { program }) {
    await this.$axios.$post('Programs', program, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getPrograms', { forceUpdate: true })
  },
  async editProgram({ dispatch }, { program, programId }) {
    await this.$axios.$patch(`admin/Programs/${programId}`, { program: program }, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getPrograms', { forceUpdate: true })
  },
  async addRecommendedProgram(_, data) {
    await this.$axios.$post('/admin/RecommendedProgram', {
      programId: data.programId,
      recommendedProgramId: data.recommendedProgramId
    }, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
  },
  async deleteRecommendedProgram(_, data) {
    await this.$axios.$delete(`/admin/RecommendedProgram/${data.recommendedProgramId}/recommendedProgram/${data.programId}`, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
  },

  async getPathologies({ commit, state }, { forceUpdate = false } = {}) {
    if (state.pathologies.length > 0 && !forceUpdate) return

    const { pathologies } = await this.$axios.$get('Pathologies', {
      headers: {
        'Cache-control': 'no-cache'
      }
    })
    commit(types.SET_PATHOLOGIES, pathologies)
  },
  async getRecommendedPrograms() {
    return await this.$axios.$get('/admin/RecommendedProgram', {
      headers: {
        'Cache-control': 'no-cache'
      }
    })
  },
  async removePathologie({ dispatch }, id) {
    const response = await this.$axios.$delete(`admin/Pathologies/pathology/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getPathologies', { forceUpdate: true })
    return response
  },
  async removeEquipment({ dispatch }, id) {
    const response = await this.$axios.$delete(`admin/Equipment/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getEquipments', { forceUpdate: true })
    return response
  },
  async deleteExercise({ dispatch }, id) {
    const response = await this.$axios.$delete(`/Exercises/exercise/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getExercises', { forceUpdate: true })
    return response
  },
  async addPathologie({ dispatch }, pathologie) {

    const response = await this.$axios.$post('admin/Pathologies', { name: pathologie }, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getPathologies', { forceUpdate: true })
    return response
  },
  async addEquipment({ dispatch }, equipment) {

    const response = await this.$axios.$post('admin/Equipments', { name: equipment }, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getEquipments', { forceUpdate: true })
    return response
  },
  async editPathologie({ dispatch }, data) {
    await this.$axios.$patch(`admin/Pathologies/${data.id}`, { name: data.name }, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getPathologies', { forceUpdate: true })
  },
  async editEquipment({ dispatch }, data) {
    await this.$axios.$patch(`admin/Equipments/${data.id}`, { name: data.name }, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getPathologies', { forceUpdate: true })
  },
  async getRanks({ commit, state }, { forceUpdate = false } = {}) {
    if (state.ranks.length > 0 && !forceUpdate) return

    const { ranks } = await this.$axios.$get('Lookups/Ranks')
    commit(types.SET_RANKS, ranks)
  },
  async editRanks(_, { ranks }) {

    await this.$axios.$patch('admin/Rank', { ranks: ranks })

  },

  async getEquipments({ commit, state }, { forceUpdate = false } = {}) {
    if (state.equipments.length > 0 && !forceUpdate) return

    const { equipments } = await this.$axios.$get('Equipments')
    commit(types.SET_EQUIPMENTS, equipments)
  },
  async getActivities({ commit }) {

    const { apaList } = await this.$axios.$get('APA')
    commit(types.SET_ACTIVITIES, apaList)
    return apaList
  },
  async addAPA({ dispatch }, apa) {
    await this.$axios.$post('APA', apa, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getActivities', { forceUpdate: true })
  },
  async editAPA({ dispatch }, apa) {
    await this.$axios.$patch(`APA/${apa.id}`, { 'apa': apa }, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getActivities', { forceUpdate: true })
  },
  async removeProgram({ dispatch }, id) {
    await this.$axios.$patch(`/Program`, { programId: id }, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getPrograms', { forceUpdate: true })
  },
  async removeActivity({ dispatch }, id) {
    await this.$axios.$delete(`APA/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
    dispatch('getActivities', { forceUpdate: true })
  },
  async addFile(_, { file, type, onUploadProgress }) {
    const formData = new FormData()
    formData.append('file', file)

    const result = await this.$axios.$post(`/Files?type=${type}&isPublic=true`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })

    return result
  },
  async addPrivateFile(_, { file, onUploadProgress }) {
    const formData = new FormData()
    formData.append('file', file)

    const result = await this.$axios.$post(`/Files?type=3`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })

    return result
  },

  async getPatients({ commit, state }) {
    if (state.patients.length > 0) return

    const { patients } = await this.$axios.$get('Admin')
    commit(types.SET_PATIENTS, patients)
  },
  async getAdminPatients({ commit }) {
    const { patients } = await this.$axios.$get("Admin/patients")
    commit(types.SET_PATIENTS, patients)
    return patients
  },
  async getAdminPatientApplications({ commit }) {
    const { applications } = await this.$axios.$get("admin/Applications/patient?statuses=None")
    commit(types.SET_PATIENTS_APPLICATIONS, applications)
    return applications
  },
  async getPatientsStats({ state, dispatch }) {
    await Promise.all(state.patients.map(async p => {
      dispatch('getPatientStats', { patientId: p.patientId })
    }))
  },
  async getPatientStats({ commit, state }, { patientId, days = 7 } = {}) {
    if (state.patients.find(patient => patient.patientId === patientId).stats[`days${days}`]) return

    const date = new Date()

    const tomorrow = date
    tomorrow.setDate(date.getDate() + 1)
    const to = tomorrow.toISOString().split('T')[0]

    const oneWeekAgo = date
    oneWeekAgo.setDate(date.getDate() - days)
    const from = oneWeekAgo.toISOString().split('T')[0]

    const params = {
      from,
      to,
      Scale: 'Day',
      Types: [
        'StepCount',
        'StandCount',
        'StandTime',
        'WalkTime',
        'ActiveTime',
        'MaxPerimeter',
        'StepCountObjective',
        'StandCountObjective',
        'StandTimeObjective',
        'WalkTimeObjective',
        'ActiveTimeObjective',
        'MaxPerimeterObjective',
        'WorkoutStars',
        'ActivityStars'
      ]
    }

    const formatedParams = Object.keys(params).map(k => {
      if (params[k] instanceof Array) {
        return params[k].map(x => `${k}=${x}`).join('&')
      } else {
        return `${k}=${params[k]}`
      }
    }).join('&')

    try {
      const { stats } = await this.$axios.$get(`/Patients/${patientId}/Stats?${formatedParams}`)
      commit(types.SET_PATIENT_STATS, { patientId, stats, days })
    } catch (e) {
      return
    }
  },
  selectPatient({ commit }, patientId) {
    commit(types.SELECT_PATIENT, patientId)
  },
  unselectPatient({ commit }, patientId) {
    commit(types.UNSELECT_PATIENT, patientId)
  },

  async getEstablishments({ commit }) {
    const { establishments } = await this.$axios.$get('/Establishments')
    commit(types.SET_ESTABLISHMENTS, establishments)
    return establishments
  },
  async getReeducatorsApplications({ commit }) {
    const { applications } = await this.$axios.$get('/admin/Applications/reeducator?statuses=None')
    commit(types.SET_REEDUCATORS_APPLICATIONS, applications)
    return applications
  },
  async getEstablishmentsApplications({ commit }) {
    const { applications } = await this.$axios.$get('/admin/Applications/Establishment?statuses=None')
    commit(types.SET_ESTABLISHMENTS_APPLICATIONS, applications)
    return applications
  },
  async getEstablishmentsStats({ state, dispatch }) {
    await Promise.all(state.establishments.map(async p => {
      dispatch('getEstablishmentStats', { establishmentId: p.establishmentId })
    }))
  },
  async addEstablishment({ dispatch }, establishment) {
    await this.$axios.$post('admin/Establishments', establishment)
    dispatch('getEstablishmentsApplications')
  },
  async getEstablishmentStats({ commit, state }, { establishmentId, days = 7 } = {}) {
    if (state.establishments.find(establishments => establishments.establishmentId === establishmentId).stats[`days${days}`]) return

    const date = new Date()

    const tomorrow = date
    tomorrow.setDate(date.getDate() + 1)
    const to = tomorrow.toISOString().split('T')[0]

    const oneWeekAgo = date
    oneWeekAgo.setDate(date.getDate() - days)
    const from = oneWeekAgo.toISOString().split('T')[0]

    const params = {
      from,
      to,
      Scale: 'Day',
      Types: [
        'StepCount',
        'StandCount',
        'StandTime',
        'WalkTime',
        'ActiveTime',
        'MaxPerimeter',
        'StepCountObjective',
        'StandCountObjective',
        'StandTimeObjective',
        'WalkTimeObjective',
        'ActiveTimeObjective',
        'MaxPerimeterObjective',
        'WorkoutStars',
        'ActivityStars'
      ]
    }

    const formatedParams = Object.keys(params).map(k => {
      if (params[k] instanceof Array) {
        return params[k].map(x => `${k}=${x}`).join('&')
      } else {
        return `${k}=${params[k]}`
      }
    }).join('&')

    try {
      const { stats } = await this.$axios.$get(`/Establishments/${establishmentId}/PatientsStats?${formatedParams}`)
      commit(types.SET_ESTABLISHMENT_STATS, { establishmentId, stats, days })
    } catch (e) {
      return
    }
  },
  selectEstablishment({ commit }, establishmentId) {
    commit(types.SELECT_ESTABLISHMENT, establishmentId)
  },
  unselectEstablishment({ commit }, establishmentId) {
    commit(types.UNSELECT_ESTABLISHMENT, establishmentId)
  },

  async getRehabilitators({ commit }) {
    const { reeducators } = await this.$axios.$get('/Reeducators')
    commit(types.SET_REHABILITATORS, reeducators)
  },
  async getRehabilitatorsPatients({ state, dispatch }) {
    await Promise.all(state.rehabilitators.map(async r => {
      dispatch('getRehabilitatorPatients', { reeducatorId: r.reeducatorId })
    }))
  },
  async getRehabilitatorPatients({ commit }, { reeducatorId }) {
    const { patients } = await this.$axios.$get(`/Reeducators/${reeducatorId}/Patients`)
    commit(types.SET_REHABILITATOR_PATIENTS, { reeducatorId, patients })
  },
  async getRehabilitatorsStats({ state, dispatch }) {
    await Promise.all(state.rehabilitators.map(async r => {
      dispatch('getRehabilitatorStats', { reeducatorId: r.reeducatorId })
    }))
  },
  async getRehabilitatorStats(/* { commit }, { reeducatorId } */) {
    // TODO: Get Reeducator stats
    // const { stats } = await this.$axios.$get(`/Reeducators/${reeducatorId}/Stats`)
    // commit(types.SET_REHABILITATOR_STATS, { reeducatorId, stats })
  },
  selectRehabilitator({ commit }, reeducatorId) {
    commit(types.SELECT_REHABILITATOR, reeducatorId)
  },
  unselectRehabilitator({ commit }, reeducatorId) {
    commit(types.UNSELECT_REHABILITATOR, reeducatorId)
  },
  async getBusinessManagers() {
    const { users } = await this.$axios.$get(`/admin/BusinessManagers`)
    return users
  },
  async getSoundsProgram() {
    const { soundProgram } = await this.$axios.$get('/SoundPrograms')
    return soundProgram
  },
  async getDevices({ commit }) {
    const { devices } = await this.$axios.$get(`/Devices/All Devices`)
    commit(types.SET_DEVICES, devices)
    return devices
  },
  async removeDevice({ dispatch }, deviceId) {
    const { devices } = await this.$axios.$delete(`/Devices/${deviceId}`)
    dispatch('getDevices')
    return devices
  },
  async editDevice({ dispatch }, device) {
    const { devices } = await this.$axios.$patch(`/Devices/${device.id}`, { device: device })
    dispatch('getDevices')
    return devices
  },
  async addDevices({ dispatch }, data) {
    const { devices } = await this.$axios.$post(`/Devices`, data)
    dispatch('getDevices')
    return devices
  },
  async getFunctionalCapacities() {
    const { functionalCapacities } = await this.$axios.$get('FunctionalCapacities')
    return functionalCapacities
  },
  async updateSoundsProgram(_, soundsProgram) {
    return await this.$axios.$patch('admin/SoundPrograms', { SoundProgram: soundsProgram })
  },
  async addDeviceEstablishment(_, data) {
    return await this.$axios.$post(`Establishments/${data.establishmentId}/devices`, { deviceId: data.device.id })
  },
  async removeDeviceEstablishment(_, data) {
    return await this.$axios.$delete(`Establishments/${data.establishmentId}/devices/${data.device.id}`)
  },
  async getEtablishmentDevices(_, establishmentDevice) {
    const { devices } = await this.$axios.$get(`Establishments/${establishmentDevice}/devices`)
    return devices
  },
  async editEstablishment({ dispatch }, establishment) {
    const { devices } = await this.$axios.$patch(`admin/Establishments/${establishment.establishmentId}`, establishment)
    dispatch('getEstablishments')
    return devices
  },
  async editEstablishmentsApplications({ dispatch }, establishment) {
    const { applications } = await this.$axios.$patch(`admin/Applications/establishment/${establishment.applicationId}`, establishment)
    dispatch('getEstablishmentsApplications')
    return applications
  },
  async resendApplicationSignUpLink(_, establishment) {
    const response = await this.$axios.$postResponse(`admin/Applications/establishment/${establishment.applicationId}/signupNotification`)
    return response
  },
  async resendReeducatorSignUpLink(_, rehabilitator) {
    return await this.$axios.$postResponse(`admin/Applications/reeducator/${rehabilitator.applicationId}/signupNotification`)

  },
  async resendPatientSignUpLink(_, patient) {
    const resp = await this.$axios.$postResponse(`admin/Applications/patient/${patient.id}/signupNotification`)
    return resp
  },
  async editRehabilitator({ dispatch }, rehabilitator) {
    const { rehabilitators } = await this.$axios.$patch(`admin/Reeducators/${rehabilitator.reeducatorId}`, rehabilitator)
    dispatch('getRehabilitators')
    return rehabilitators
  },
  async editRehabilitatorApplication({ dispatch }, rehabilitator) {
    const { rehabilitators } = await this.$axios.$patch(`admin/Applications/reeducator/${rehabilitator.applicationId}`, rehabilitator)
    dispatch('getReeducatorsApplications')
    return rehabilitators
  },
  async closeAccountEstablishment({ dispatch }, establishment) {
    const resp = await this.$axios.$patch(`admin/Establishments/DesactivateEstablishment/${establishment.establishmentId}`, { isEnabled: !establishment.isEnabled })
    dispatch('getEstablishments')
    return resp
  },
  async editAdminPatient({ dispatch }, patient) {
    const resp = await this.$axios.$patch(`admin/patient/${patient.patientId}`, patient)
    dispatch('getAdminPatients')
    return resp
  },
  async editAdminPatientApplication({ dispatch }, patient) {
    const resp = await this.$axios.$patch(`admin/Applications/patient/${patient.id}`, patient)
    dispatch('getAdminPatientApplications')
    return resp
  },
  async closeRehabilitator({ dispatch }, rehabilitator) {
    const resp = await this.$axios.$patch(`admin/Reeducators/DesactivateReeducator/${rehabilitator.reeducatorId}`, { isEnabled: !rehabilitator.isEnabled })
    dispatch('getRehabilitators')
    return resp
  },
  async closeAccountPatient({ dispatch }, patient) {
    const resp = await this.$axios.$patch(`admin/Patients/DesactivatePatient/${patient.patientId}`, { isEnabled: !patient.isEnabled })
    dispatch('getAdminPatients')
    return resp
  }
}

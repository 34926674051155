import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'

export const state = {
  patients: [],
  patientApplications: [],
  establishments: [],
  establishmentsApplications: [],
  reeducatorsApplications: [],
  rehabilitators: [],
  selectedPatients: [],
  selectedEstablishments: [],
  selectedRehabilitators: [],
  exercises: [],
  programs: [],
  pathologies: [],
  equipments: [],
  ranks: [],
  activities: [],
  devices: []
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
